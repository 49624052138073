import React, { Fragment, lazy, Suspense, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Sidebar, Header } from '@stb-component-layout';
import './styles.scss';
import { useWindowSize } from '@stb-utils/hooks';
import { setOpenMenu } from '@stb-component/layouts/Header/headerActions';
import { connect, useSelector } from 'react-redux';

import moment from 'moment';
import 'moment/locale/id';

const BateryManagementLanding = lazy(() =>
  import('@stb-module-batery-management/screens/BateryManagementLanding')
);
const BlacklistBatteryLanding = lazy(() =>
  import('@stb-module-batery-management/screens/BlacklistBatteryLanding')
);
const LiveBatteryMonitoring = lazy(() =>
  import('@stb-module-live-battery-monitoring/screens/LiveBatteryMonitoring')
);
const LiveSgbAndBatteryMonitoring = lazy(() =>
  import('@stb-module-live-battery-monitoring/screens/LiveSgbAndBatteryMonitoring')
);
const LiveSgbAndUserMonitoring = lazy(() =>
  import('@stb-module-live-battery-monitoring/screens/LiveSgbAndUserMonitoring')
);
const LiveMotorcycleColorMonitoring = lazy(() =>
  import('@stb-module-live-battery-monitoring/screens/LiveMotorcycleColorMonitoring')
);

const PaketBateryLanding = lazy(() =>
  import('@stb-module-paket-baterai/screens/PaketBateryLanding')
);

const NewBateryPackage = lazy(() => import('@stb-module-paket-baterai/screens/NewBateryPackage'));

const MonitorTukarBaterai = lazy(() =>
  import('@stb-module-paket-baterai/screens/MonitorPaketBattery')
);

const PartnerSTBLanding = lazy(() => import('@stb-module-partner-stb/screens/PartnerSTB'));

const PartnerSTBInformation = lazy(() =>
  import('@stb-module-partner-stb/screens/PartnerInformation')
);

const SgbManagmenetLanding = lazy(() =>
  import('@stb/module/sgb-management/screens/SGBManagementLanding')
);

const SgbManagementDetail = lazy(() => import('@stb-management-sgb/screens/SGBDetail'));

const SubsciptionLanding = lazy(() =>
  import('@stb-module-subscription/screens/SubscriptionLanding')
);

const GroupSTBLanding = lazy(() => import('@stb-module-group-stb/screens/GroupSTBLanding'));

const GroupSTBDetailMotor = lazy(() => import('@stb-module-group-stb/screens/GroupSTBDetailMotor'));

const GroupSTBDetailSTB = lazy(() => import('@stb-module-group-stb/screens/GroupSTBDetailSTB'));

const AuditTrail = lazy(() => import('@stb-module-audit-trail/screens/Main'));

const SystemParameter = lazy(() =>
  import('@stb-module-system-paramater/screens/SystemParameterLanding')
);

const ChangePassword = lazy(() => import('@stb-module-profile/screens/ChangePassword'));

const MonitorPaketBatteryList = lazy(() =>
  import('@stb-module-paket-baterai/screens/MonitorPaketBatteryList')
);

const OpenedDoorMonitoring = lazy(() =>
  import('@stb-module-door-monitoring/screens/OpenDoorLanding')
);

const BatteryInventory = lazy(() => import('@stb-module-mitra/screens/BatteryInventoryLanding'));
const BatteryInventoryAdd = lazy(() => import('@stb-module-mitra/screens/BatteryInventoryAdd'));
const BatteryInventoryDetail = lazy(() =>
  import('@stb-module-mitra/screens/BatteryInventoryDetail')
);
const PartnerBalanceLanding = lazy(() => import('@stb-module-mitra/screens/PartnerBalanceLanding'));
const PartnerBalanceDetail = lazy(() => import('@stb-module-mitra/screens/PartnerBalanceDetail'));
const Report = lazy(() => import('@stb-module-report/screens/Report'));
const ReportSicepat = lazy(() => import('@stb-module-report/report-sicepat/screens'));
const ReportPaketMembership = lazy(() =>
  import('@stb-module-report/report-paket-membership/screens')
);
const ReportVoltaPlus = lazy(() => import('@stb-module-report/report-volta-plus/screens'));
const ReportScb = lazy(() => import('@stb-module-report/report-scb/screens'));
const ReportPembelianMembership = lazy(() =>
  import('@stb-module-report/report-pembelian-membership/screens')
);
const ReportProfit = lazy(() => import('@stb-module-report/report-profit/screens'));
const BroadcastNotificationSend = lazy(() =>
  import('@stb-module-broadcast-notification/screens/BroadcastNotificationSend')
);
const BroadcastNotificationLanding = lazy(() =>
  import('@stb-module-broadcast-notification/screens/BroadcastNotificationLanding')
);
const SgbTokenLanding = lazy(() => import('@stb-module-token/screens/SgbTokenLanding'));
const SgbTokenDetail = lazy(() => import('@stb-module-token/screens/SgbTokenDetail'));
const PackagePurchasesLanding = lazy(() =>
  import('@stb-module-package-purchases/screens/PackagePurchasesLanding')
);
const PackagePurchaseTransactionDetail = lazy(() =>
  import('@stb-module-package-purchases/screens/PackagePurchaseTransactionDetail')
);
const PackagePurchasesLandingV2 = lazy(() =>
  import('@stb-module-package-purchases/screens/PackagePurchasesLandingV2')
);
const PackagePurchaseTransactionDetailV2 = lazy(() =>
  import('@stb-module-package-purchases/screens/PackagePurchaseTransactionDetailV2')
);
const ProductVoucherPackagesLanding = lazy(() =>
  import('@stb-module-product-voucher-packages/screens/Landing')
);
const ProductVoucherPackagesCreate = lazy(() =>
  import('@stb-module-product-voucher-packages/screens/Create')
);
const ProductVoucherPackagesEdit = lazy(() =>
  import('@stb-module-product-voucher-packages/screens/Edit')
);
const SCBMonitorLanding = lazy(() => import('@stb-module-scb-monitor/screens/SCBMonitorLanding'));
const ClaimBatteryLanding = lazy(() =>
  import('@stb-module-claim-battery/screens/ClaimBatteryLanding')
);
const PointManagementLanding = lazy(() => import('@stb-module-point-management/screens/Landing'));
const LiveBatteryLockCabinetMonitoring = lazy(() =>
  import('@stb-module-live-battery-monitoring/screens/LiveBatteryLockCabinetMonitoring')
);
const GroupBatteryLanding = lazy(() =>
  import('@stb-module-group-battery/screens/GroupBatteryLanding')
);

function Fallback() {
  return (
    <div className="d-flex flex-row justify-content-center">
      <p>Loading...</p>
    </div>
  );
}

moment.locale('id');

function MainNavigation({ setOpenMenu, menuIsOpen }) {
  const windowSize = useWindowSize();
  const isDesktop = windowSize > 1024;

  useEffect(() => {
    setOpenMenu(isDesktop);
  }, [windowSize]);

  const user = useSelector((state) => state.auth.session.user);

  const listRoutes = useMemo(() => {
    switch (user?.role_user_id) {
      case parseInt(process.env.REACT_APP_ROLE_FACTORY):
        return (
          <Switch>
            <Route exact path="/batery-management">
              <BateryManagementLanding />
            </Route>
            <Route exact path="/change-password">
              <ChangePassword />
            </Route>
            <Route exact path="/preference">
              <SystemParameter isUserPreferences={true} />
            </Route>
            <Redirect from="/" to={'/batery-management'} />
          </Switch>
        );
      case parseInt(process.env.REACT_APP_ROLE_PERTAMINA):
        return (
          <Switch>
            <Route exact path="/monitor-battery-list">
              <MonitorPaketBatteryList />
            </Route>
            <Route exact path="/monitor-battery-exchange">
              <MonitorTukarBaterai />
            </Route>
            <Route exact path="/report">
              <Report />
            </Route>
            <Route exact path="/change-password">
              <ChangePassword />
            </Route>
            <Route exact path="/preference">
              <SystemParameter isUserPreferences={true} />
            </Route>
            <Redirect from="/" to={'/monitor-battery-list'} />
          </Switch>
        );
      case parseInt(process.env.REACT_APP_ROLE_PLN):
        return (
          <Switch>
            <Route exact path={['/stb-management', '/stb-management/:flag?']}>
              <SgbManagmenetLanding key="PARTNER" type="PARTNER" />
            </Route>
            <Route exact path={['/stb-management', '/stb-management/:flag?']}>
              <SgbManagmenetLanding key="PARTNER" type="PARTNER" />
            </Route>
            <Route
              exact
              path={[
                '/stb-management/add',
                '/stb-management/edit/:id',
                '/stb-management/edit/:id/:flag1?/:flag2?/:flag3?',
              ]}
            >
              <SgbManagementDetail key="PARTNER" type="PARTNER" />
            </Route>
            <Route exact path="/monitor-battery-list">
              <MonitorPaketBatteryList />
            </Route>
            <Route exact path="/monitor-battery-exchange">
              <MonitorTukarBaterai />
            </Route>
            <Route exact path="/report">
              <Report />
            </Route>
            <Route exact path="/change-password">
              <ChangePassword />
            </Route>
            <Route exact path="/preference">
              <SystemParameter isUserPreferences={true} />
            </Route>
            <Redirect from="/" to={'/monitor-battery-list'} />
          </Switch>
        );
      case parseInt(process.env.REACT_APP_ROLE_ARKADIA):
        return (
          <Switch>
            <Route exact path="/monitor-battery-list">
              <MonitorPaketBatteryList />
            </Route>
            <Route exact path="/monitor-battery-exchange">
              <MonitorTukarBaterai />
            </Route>
            <Route exact path="/report">
              <Report />
            </Route>
            <Route exact path="/change-password">
              <ChangePassword />
            </Route>
            <Route exact path="/preference">
              <SystemParameter isUserPreferences={true} />
            </Route>
            <Redirect from="/" to={'/monitor-battery-list'} />
          </Switch>
        );
      default:
        return (
          <Switch>
            <Route path="/audit-trail">
              <AuditTrail />
            </Route>
            <Route exact path="/system-parameter">
              <SystemParameter isUserPreferences={false} />
            </Route>
            <Route exact path="/batery-management">
              <BateryManagementLanding />
            </Route>
            <Route exact path="/group-battery">
              <GroupBatteryLanding />
            </Route>
            <Route exact path="/batery-management/blacklisted">
              <BlacklistBatteryLanding />
            </Route>
            <Route exact path="/paket-baterai">
              <PaketBateryLanding />
            </Route>
            <Route exact path={['/paket-baterai/add-package', '/paket-baterai/edit-package/:id']}>
              <NewBateryPackage />
            </Route>
            <Route exact path="/partner-stb">
              <PartnerSTBLanding />
            </Route>
            <Route path={['/partner-stb/add', '/partner-stb/edit/:id']}>
              <PartnerSTBInformation />
            </Route>
            <Route exact path={['/stb-management', '/stb-management/:flag?']}>
              <SgbManagmenetLanding key="GENERAL" type="GENERAL" />
            </Route>
            <Route
              exact
              path={[
                '/stb-management/add',
                '/stb-management/edit/:id',
                '/stb-management/edit/:id/:flag1?/:flag2?/:flag3?',
              ]}
            >
              <SgbManagementDetail key="GENERAL" type="GENERAL" />
            </Route>
            <Route exact path="/subscription">
              <SubsciptionLanding />
            </Route>
            <Route exact path="/monitor-battery-exchange">
              <MonitorTukarBaterai />
            </Route>
            <Route exact path="/live-battery-monitoring">
              <LiveBatteryMonitoring />
            </Route>
            <Route exact path="/live-sgb-and-battery-monitoring">
              <LiveSgbAndBatteryMonitoring />
            </Route>
            <Route exact path="/live-sgb-and-user-monitoring">
              <LiveSgbAndUserMonitoring />
            </Route>
            <Route exact path="/live-motorcycle-color-monitoring">
              <LiveMotorcycleColorMonitoring />
            </Route>
            <Route exact path="/group-stb">
              <GroupSTBLanding />
            </Route>
            <Route exact path={['/group-stb/detail-stb/:id/', '/group-stb/detail-stb/:id/:name']}>
              <GroupSTBDetailSTB />
            </Route>
            <Route exact path="/group-stb/detail-motor/:id/:name?">
              <GroupSTBDetailMotor />
            </Route>
            <Route exact path="/change-password">
              <ChangePassword />
            </Route>
            <Route exact path="/preference">
              <SystemParameter isUserPreferences={true} />
            </Route>
            <Route exact path="/monitor-battery-list">
              <MonitorPaketBatteryList />
            </Route>
            <Route exact path="/door-monitoring">
              <OpenedDoorMonitoring />
            </Route>
            <Route exact path={['/mitra/sgb', '/mitra/sgb/:flag?']}>
              <SgbManagmenetLanding key="MITRA" type="MITRA" />
            </Route>
            <Route
              exact
              path={[
                '/mitra/sgb/add',
                '/mitra/sgb/edit/:id',
                '/mitra/sgb/edit/:id/:flag1?/:flag2?/:flag3?',
              ]}
            >
              <SgbManagementDetail key="MITRA" type="MITRA" />
            </Route>
            <Route exact path="/kemitraan/battery">
              <BatteryInventory />
            </Route>
            <Route exact path="/kemitraan/battery/add">
              <BatteryInventoryAdd />
            </Route>
            <Route exact path="/kemitraan/battery/detail/:id">
              <BatteryInventoryDetail />
            </Route>
            <Route exact path="/kemitraan/balance">
              <PartnerBalanceLanding />
            </Route>
            <Route exact path="/kemitraan/balance/detail/:id">
              <PartnerBalanceDetail />
            </Route>
            <Route exact path="/report">
              <Report />
            </Route>
            <Route exact path="/report-sicepat">
              <ReportSicepat />
            </Route>
            <Route exact path="/report-paket-membership">
              <ReportPaketMembership />
            </Route>
            <Route exact path="/report-volta-plus">
              <ReportVoltaPlus />
            </Route>
            <Route exact path="/report-scb">
              <ReportScb />
            </Route>
            <Route exact path="/report-pembelian-membership">
              <ReportPembelianMembership />
            </Route>
            <Route exact path="/report-profit">
              <ReportProfit />
            </Route>
            <Route exact path="/broadcast-notification">
              <BroadcastNotificationLanding />
            </Route>
            <Route exact path="/broadcast-notification/send">
              <BroadcastNotificationSend />
            </Route>
            <Route exact path="/token-sgb">
              <SgbTokenLanding />
            </Route>
            <Route exact path="/token-sgb/detail/:id">
              <SgbTokenDetail />
            </Route>
            <Route exact path="/package-purchases">
              <PackagePurchasesLanding />
            </Route>
            <Route exact path="/package-purchases/detail/:id">
              <PackagePurchaseTransactionDetail />
            </Route>
            <Route exact path="/scb-package-purchases">
              <PackagePurchasesLandingV2 type="scb" key="1" />
            </Route>
            <Route exact path="/booking-package-purchases">
              <PackagePurchasesLandingV2 type="booking" key="2" />
            </Route>
            <Route exact path="/entrust-package-purchases">
              <PackagePurchasesLandingV2 type="entrust" key="3" />
            </Route>
            <Route exact path="/scb-package-purchases/detail/:id">
              <PackagePurchaseTransactionDetailV2 type="scb" key="1" />
            </Route>
            <Route exact path="/booking-package-purchases/detail/:id">
              <PackagePurchaseTransactionDetailV2 type="booking" key="2" />
            </Route>
            <Route exact path="/entrust-package-purchases/detail/:id">
              <PackagePurchaseTransactionDetailV2 type="entrust" key="3" />
            </Route>
            <Route exact path="/product-voucher-packages">
              <ProductVoucherPackagesLanding />
            </Route>
            <Route exact path="/product-voucher-packages/create">
              <ProductVoucherPackagesCreate />
            </Route>
            <Route exact path="/product-voucher-packages/detail/:id">
              <ProductVoucherPackagesEdit />
            </Route>
            <Route exact path="/monitor-scb">
              <SCBMonitorLanding />
            </Route>
            <Route exact path="/claim-battery">
              <ClaimBatteryLanding />
            </Route>
            <Route exact path="/point-management">
              <PointManagementLanding />
            </Route>
            <Route exact path="/battery-lock-cabinet">
              <LiveBatteryLockCabinetMonitoring />
            </Route>
            <Route exact path="/group-battery">
              <GroupBatteryLanding />
            </Route>

            <Redirect from="/" to={'/stb-management'} />
          </Switch>
        );
    }
  });

  return (
    <HashRouter>
      <AnimatePresence>
        <Fragment>
          <Header />
          <Sidebar />
          <div
            className="content"
            style={{
              marginLeft: menuIsOpen && isDesktop ? '260px' : 0,
            }}
          >
            <div className="main-content">
              <Suspense fallback={<Fallback />}>{listRoutes}</Suspense>
            </div>
          </div>
        </Fragment>
      </AnimatePresence>
    </HashRouter>
  );
}

const mapStateToProps = (state) => ({
  menuIsOpen: state.header.menuIsOpen,
});
const mapDispatchToProps = {
  setOpenMenu: (payload) => setOpenMenu(payload),
};

MainNavigation.propTypes = {
  setOpenMenu: PropTypes.func.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);
